import { makeStyles } from '@material-ui/core/styles';
import { CustomerType, UserType } from '@prisma/client';
import useAuthedUser from '../hooks/useAuthedUser';
import useSwitchRole from '../hooks/useSwitchRole';
import { hasRole, roles } from '../lib/permissions';
import { useAuth } from './AuthProvider';

const useStyles = makeStyles((theme) => ({
  input: {
    fontSize: theme.typography.fontSize,
    fontFamily: theme.typography.fontFamily,
    padding: theme.spacing(0.5),
    width: 'max-content',
    marginLeft: 10,
  },
}));

export default function DemoRolePicker() {
  const { user } = useAuth();
  const classes = useStyles();
  const me = useAuthedUser();
  const switchRole = useSwitchRole();
  const isSuperAdmin = hasRole(user, [roles.SUPERADMIN]);
  const isDemoSchool = me.data?.school?.status === CustomerType.EXAMPLE;
  const activeRole = me.data?.type;

  const handleChange = (event) => {
    switchRole.mutate({ role: event.target.value });
  };

  if (!isDemoSchool || isSuperAdmin) return null;

  return (
    <select
      name="activeRole"
      value={activeRole}
      onChange={handleChange}
      className={classes.input}
      disabled={switchRole.isLoading}
    >
      <option key={UserType.TEACHER} value={UserType.TEACHER}>
        Teacher
      </option>
      <option key={UserType.ADMIN} value={UserType.ADMIN}>
        Admin
      </option>
    </select>
  );
}
