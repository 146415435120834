import Router from 'next/router';
import { Amplify, Hub } from 'aws-amplify';
import { baseUrl, region, userPoolId, userPoolWebClientId } from '../constants';
import getSignInFailureReason from '../utils/getSignInFailureReason';

const setLoginError = (error) => {
  globalThis.setLoginError(error);
  Router.events.off('routeChangeComplete', setLoginError);
};

Hub.listen('auth', (data) => {
  if (data.payload.event === 'signIn_failure') {
    const error = getSignInFailureReason(data.payload.data.message);
    Router.events.on('routeChangeComplete', () => setLoginError(error));
  }
});

Amplify.configure({
  Auth: {
    region,
    userPoolId,
    userPoolWebClientId,
    mandatorySignIn: true,
    oauth: {
      domain: 'edcover.auth.eu-west-2.amazoncognito.com',
      scope: ['email', 'profile', 'openid'],
      redirectSignIn: baseUrl,
      redirectSignOut: `${baseUrl}/login`,
      responseType: 'code',
    },
  },
  ssr: true,
});
