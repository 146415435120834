import Link from '@material-ui/core/Link';

const links = [
  {
    title: 'About',
    href: 'https://edcover.co.uk/',
  },
  {
    title: 'Privacy Policy',
    href: 'https://publicedcover.s3.eu-west-2.amazonaws.com/Edcover+Privacy+Policy.pdf',
  },
  {
    title: 'Cookie Policy',
    href: 'https://publicedcover.s3.eu-west-2.amazonaws.com/Edcover+Cookie+Policy.pdf',
  },
  {
    title: 'Terms of Service',
    href: 'https://publicedcover.s3.eu-west-2.amazonaws.com/Edcover+Terms.pdf',
  },
];

export default function Footer() {
  return (
    <div
      style={{
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        padding: 10,
        backgroundColor: '#3f51b5',
        color: 'white',
        fontSize: 13,
      }}
      className="footer"
    >
      <span style={{ marginRight: 8, lineHeight: '25px' }}>
        &copy; {new Date().getFullYear()} Prodeo Ltd (t/a Edcover)
      </span>
      {links.map((link) => (
        <Link
          key={link.href}
          href={link.href}
          target="_blank"
          rel="noopener noreferrer"
          underline="none"
          style={{
            marginLeft: 8,
            marginRight: 8,
            color: 'white',
            lineHeight: '25px',
          }}
        >
          {link.title}
        </Link>
      ))}
    </div>
  );
}
