export function stopBrowserBack(callback) {
  window.history.pushState(null, '', window.location.href);
  window.onpopstate = () => {
    window.history.pushState(null, '', window.location.href);
    callback();
    startBrowserBack();
  };
}

export function startBrowserBack() {
  window.onpopstate = undefined;
  window.history.back();
}
