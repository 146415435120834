export const publicRoutes = [
  '/login',
  '/logout',
  '/account/reset',
  '/account/forgot',
  '/account/confirm',
  '/cover/[id]/view',
];

export const supportEmail = 'info@edcover.co.uk';
export const defaultErrorMessage =
  'Something went wrong, please try again later.';

export const passwordPolicyInfo =
  'Passwords must be at least 8 characters and should include at least 1 number and 1 uppercase letter.';

export const region = 'eu-west-2';
export const userPoolId = 'eu-west-2_gQ7ynjx30';
export const userPoolWebClientId = '3oj9esmvri32btr9d0coha3li0';

export const yearGroups = [
  'EYFS',
  'Y1',
  'Y2',
  'Y3',
  'Y4',
  'Y5',
  'Y6',
  'Y7',
  'Y8',
  'Y9',
  'Y10',
  'Y11',
  'Y12',
  'Y13',
];

export const baseUrl =
  process.env.NODE_ENV === 'development'
    ? 'http://localhost:3000'
    : 'https://dashboard.edcover.co.uk';

export const initialFeedbackOptions = [
  { text: 'Completion of work' },
  { text: 'Attitude to learning' },
];

export const emailSubjects = {
  ALLOCATED_COVER: 'You have been allocated cover',
  FEEDBACK_RECEIVED: 'You have received feedback on a lesson',
  DELEGATED_COVER: 'You have been requested to provide cover work',
  CANCELLED_COVER: 'Notice of cancelled cover',
  WORK_SET: 'Notice of departmental absence',
};

export const daysOfWeek = {
  M: 'Mon',
  TU: 'Tue',
  W: 'Wed',
  TH: 'Thu',
  F: 'Fri',
};

export const MAX_GUEST_USERS = 5;
export const MAX_POLICY_UPLOADS = 10;

export const USER_NOT_FOUND = `You do not have an active account with us, please email ${supportEmail} for more information.`;
export const USER_NOT_ACTIVATED =
  'Your account must be activated before you can access Edcover. Contact your school administrator.';
export const LOGIN_DISABLED = `Your subscription is no longer active. Please email ${supportEmail} for more information.`;
