import Link from 'next/link';
import { useState } from 'react';
import { useRouter } from 'next/router';
import AppBar from '@material-ui/core/AppBar';
import Container from '@material-ui/core/Container';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import MenuIcon from '@material-ui/icons/Menu';
import Toolbar from '@material-ui/core/Toolbar';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import SchoolIcon from '@material-ui/icons/School';
import PeopleIcon from '@material-ui/icons/People';
import DateRangeIcon from '@material-ui/icons/DateRange';
import SettingsIcon from '@material-ui/icons/Settings';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import DashboardIcon from '@material-ui/icons/Dashboard';
import TimelineIcon from '@material-ui/icons/Timeline';
import HelpIcon from '@material-ui/icons/Help';
import { useAuth } from './AuthProvider';
import { publicRoutes } from '../constants';
import { actions, hasPermission } from '../lib/permissions';
import Logo from './Logo';
import Footer from './Footer';
import DemoRolePicker from './DemoRolePicker';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  drawer: {
    [theme.breakpoints.up('md')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: '#fff',
    boxShadow: 'inset 0 -2px 0 #e8e8e8',
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('lg')]: {
      display: 'none',
    },
    color: '#000000DE',
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
  },
  navBtn: {
    marginLeft: 30,
  },
}));

const primaryNavItems = (user) => [
  {
    label: 'Dashboard',
    icon: <DashboardIcon />,
    href: '/',
  },
  {
    label: hasPermission(user, actions.MANAGE_COVER)
      ? 'Manage Cover'
      : 'Cover Overview',
    icon: <DateRangeIcon />,
    href: '/cover/manage',
    alternativePaths: '/cover/[id]/manage',
  },
  {
    label: 'Manage Users',
    icon: <PeopleIcon />,
    href: '/users',
    hidden: !hasPermission(user, actions.MANAGE_USERS),
  },
  {
    label: 'Manage Schools',
    icon: <SchoolIcon />,
    href: '/schools',
    hidden: !hasPermission(user, actions.MANAGE_SCHOOLS),
  },
  {
    label: 'Analytics',
    icon: <TimelineIcon />,
    href: '/userstats',
    hidden: !hasPermission(user, actions.VIEW_STATS),
  },
];

const secondaryNavItems = [
  {
    label: 'Help & Support',
    icon: <HelpIcon />,
    href: '/support',
  },
  {
    label: 'Settings',
    icon: <SettingsIcon />,
    href: '/settings',
  },
  {
    label: 'Log Out',
    icon: <ExitToAppIcon />,
    href: '/logout',
  },
];

function LinkTab(props) {
  return (
    <Tab
      component="a"
      onClick={(event) => {
        event.preventDefault();
      }}
      disableRipple
      textColor="textPrimary"
      style={{
        color: 'black',
        minWidth: 30,
        margin: 8,
      }}
      {...props}
    />
  );
}

export default function Layout({ children }) {
  const [mobileOpen, setMobileOpen] = useState(false);
  const classes = useStyles();
  const theme = useTheme();
  const router = useRouter();
  const { user } = useAuth();

  if (publicRoutes.includes(router.pathname))
    return (
      <>
        <div className="content">{children}</div>
        <Footer />
      </>
    );

  if (!user) return null;

  const primaryNavItemsForUser = primaryNavItems(user).filter(
    (item) => !item.hidden
  );
  const allNavItems = [...primaryNavItemsForUser, ...secondaryNavItems];
  const currentNavItemIndex = allNavItems.findIndex(
    (item) =>
      item.alternativePaths?.includes(router.pathname) ||
      router.pathname === item.href
  );

  const drawer = (
    <div>
      <div style={{ display: 'flex', padding: 16 }}>
        <Logo width={200} height={75} />
      </div>
      <Divider />
      <List>
        {primaryNavItemsForUser.map(({ icon, label, href }) => {
          return (
            <Link href={href} key={label} passHref legacyBehavior>
              <ListItem button selected={router.pathname === href}>
                <ListItemIcon style={{ padding: '0 8px' }}>{icon}</ListItemIcon>
                <ListItemText primary={label} />
              </ListItem>
            </Link>
          );
        })}
      </List>
      <Divider />
      <List>
        {secondaryNavItems.map(({ icon, label, href }) => (
          <Link href={href} key={label} passHref legacyBehavior>
            <ListItem button selected={router.pathname === href}>
              <ListItemIcon style={{ padding: '0 8px' }}>{icon}</ListItemIcon>
              <ListItemText primary={label} />
            </ListItem>
          </Link>
        ))}
      </List>
    </div>
  );

  function handleDrawerToggle() {
    setMobileOpen(!mobileOpen);
  }

  return (
    <div className={classes.root}>
      <AppBar position="fixed" className={classes.appBar} elevation={0}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="Open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            className={classes.menuButton}
          >
            <MenuIcon />
          </IconButton>
          <Logo width={120} />
          <Hidden mdDown implementation="css">
            <Tabs
              indicatorColor="secondary"
              value={currentNavItemIndex === -1 ? 0 : currentNavItemIndex}
            >
              {allNavItems.map(({ href, label }) => (
                <Link href={href} key={label} passHref legacyBehavior>
                  <LinkTab href={href} label={label} />
                </Link>
              ))}
            </Tabs>
          </Hidden>
          <DemoRolePicker />
        </Toolbar>
      </AppBar>

      <nav className={mobileOpen ? classes.drawer : ''}>
        <Hidden lgUp implementation="css">
          <Drawer
            variant="temporary"
            anchor={theme.direction === 'rtl' ? 'right' : 'left'}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true,
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
      </nav>
      <div className={classes.content}>
        <div className={classes.toolbar} />
        <Container maxWidth="lg" style={{ paddingTop: 30, paddingBottom: 30 }}>
          {children}
        </Container>
      </div>
    </div>
  );
}
