import { UserType as roles } from '@prisma/client';

const actions = {
  MANAGE_USERS: [roles.ADMIN],
  MANAGE_SCHOOLS: [roles.SUPERADMIN],
  MANAGE_COVER: [roles.MANAGER, roles.ADMIN],
  MANAGE_SETTINGS: [roles.ADMIN],
  VIEW_STATS: [roles.MANAGER, roles.ADMIN],
};

const hasPermission = (user, action) => {
  const userGroups = user.signInUserSession.idToken.payload['cognito:groups'];
  if (!userGroups) return false;
  if (userGroups.includes(roles.SUPERADMIN)) return true;
  return userGroups.some((role) => action.includes(role));
};

const hasRole = (user, permittedRoles) => {
  const userGroups = user.signInUserSession.idToken.payload['cognito:groups'];
  if (!userGroups) return false;
  return userGroups.some((role) => permittedRoles.includes(role));
};

export { roles, actions, hasRole, hasPermission };
