import { useSnackbar } from 'notistack';
import { useMutation, useQueryClient } from 'react-query';
import { useAuth } from '../components/AuthProvider';
import ApiError from '../utils/ApiError';

async function switchDemoRole(values) {
  const response = await fetch('/api/users/switch-role', {
    method: 'PUT',
    body: JSON.stringify(values),
    headers: {
      'Content-Type': 'application/json',
    },
  });
  const responseBody = await response.json();
  if (!response.ok) throw new ApiError(responseBody.message, response.status);
  return responseBody;
}

export default function useSwitchRole() {
  const { refresh } = useAuth();
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  return useMutation(switchDemoRole, {
    onError: (error) => {
      enqueueSnackbar(error.message ?? 'Failed to switch role', {
        variant: 'error',
      });
    },
    onSuccess: (data, variables) => {
      refresh();
      queryClient.invalidateQueries('me');
    },
  });
}
